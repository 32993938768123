import React from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import { css } from 'styled-components/macro' //eslint-disable-line

import LogoImage from '../../images/logo.svg'

const Container = tw.div`relative bg-gray-200 -mx-8 -mb-8 px-8`
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap justify-between`

const Column = tw.div`md:w-1/5`
const WideColumn = tw(
  Column
)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`

const ColumnHeading = tw.h5`font-bold`

const LinkList = tw.ul`mt-4 text-sm font-medium`
const LinkListItem = tw.li`mt-3`
const Link = tw.a`border-b-2 border-transparent hocus:text-primary-500 hocus:border-primary-500 pb-1 transition duration-300`

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`
const LogoImg = styled.img`
  width: 100px;
`

const LogoText = tw.h5`text-sm ml-2 sm:text-lg md:text-lg lg:text-lg font-black text-primary-500 md:text-left`

export default () => {
  return (
    <Container>
      <FiveColumns>
        <WideColumn>
          <LogoContainer>
            <LogoImg src={LogoImage} />
            <LogoText>Dream House Construction Partners LTD</LogoText>
          </LogoContainer>
        </WideColumn>
        <Column>
          <ColumnHeading>Quick Links</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href='/'>Home</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href='about'>About</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href='contact'>Contact </Link>
            </LinkListItem>
            <LinkListItem>
              <Link href='gallery'>Services & Gallery</Link>
            </LinkListItem>
          </LinkList>
        </Column>
      </FiveColumns>
    </Container>
  )
}
