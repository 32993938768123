import React from 'react'
import AnimationRevealPage from 'helpers/AnimationRevealPage.js'
import tw from 'twin.macro'
import { css } from 'styled-components/macro' //eslint-disable-line
import Header from 'components/headers/light.js'
import { navLinks } from 'components/headers/CustomHeader'
import Footer from 'components/footers/SimpleFiveColumn.js'
import MainFeature1 from 'components/features/TwoColWithButton.js'

import AboutUs from 'images/about-us.png'

const Subheading = tw.span`uppercase tracking-wider text-sm`
export default () => {
  return (
    <AnimationRevealPage>
      <Header links={navLinks} />
      <MainFeature1
        subheading={<Subheading>About</Subheading>}
        heading='Dream House Construction Partners LTD'
        imageSrc={AboutUs}
      />

      <Footer />
    </AnimationRevealPage>
  )
}
