import React from 'react'
import AnimationRevealPage from 'helpers/AnimationRevealPage.js'
import { css } from 'styled-components/macro' //eslint-disable-line

import Header from 'components/headers/light.js'
import Footer from 'components/footers/SimpleFiveColumn.js'
import ContactUsForm from 'components/forms/TwoColContactUsWithIllustrationFullForm.js'
import { navLinks } from 'components/headers/CustomHeader'

export default () => {
  return (
    <AnimationRevealPage>
      <Header links={navLinks} />
      <ContactUsForm />
      <Footer />
    </AnimationRevealPage>
  )
}
