import React from 'react'
import GlobalStyles from 'styles/GlobalStyles'
import { css } from 'styled-components/macro' //eslint-disable-line
import AboutUsPage from 'pages/AboutUs.js'
import ContactUsPage from 'pages/ContactUs.js'
import Gallery from 'pages/Gallery.js'

import LandingPage from 'main/LandingPage.js'

import { BrowserRouter, Routes, Route } from 'react-router-dom'

export default function App() {
  return (
    <>
      <GlobalStyles />
      <BrowserRouter>
        <Routes>
          <Route index element={<LandingPage />} />
          <Route path='about' element={<AboutUsPage />} />
          <Route path='contact' element={<ContactUsPage />} />
          <Route path='gallery' element={<Gallery />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}
