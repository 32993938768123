import React from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import tw from 'twin.macro'
import { css } from 'styled-components/macro' //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase
} from 'components/misc/Headings.js'
// import { ReactComponent as ArrowLeftIcon } from '../../images/arrow-left-2-icon.svg'
// import { ReactComponent as ArrowRightIcon } from '../../images/arrow-right-2-icon.svg'
import { ReactComponent as SvgDecoratorBlob1 } from '../../images/svg-decorator-blob-4.svg'
import { ReactComponent as SvgDecoratorBlob2 } from '../../images/svg-decorator-blob-5.svg'

import img1 from '../../images/background-hero.png'
import img2 from '../../images/img2.png'
import img3 from '../../images/img3.png'
import img4 from '../../images/img4.png'
import img5 from '../../images/img5.png'
import img6 from '../../images/img6.png'
import img7 from '../../images/img7.png'
import img8 from '../../images/img8.png'
import img9 from '../../images/img9.png'
import img10 from '../../images/img10.png'
import img11 from '../../images/img11.png'
import img12 from '../../images/img12.png'
import logo from '../../images/logo.svg'

import 'slick-carousel/slick/slick.css'

const Container = tw.div`relative`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`

const TestimonialSliderContainer = tw.div`mt-4`

const TestimonialSlider = styled(Slider)`
  .slick-dots {
    ${tw`mt-2 flex justify-center flex-wrap overflow-hidden`}
    li {
      ${tw`mx-1`}
    }
    button {
      ${tw`p-0`}
    }
  }
`

// const SliderControlButtonContainer = styled.div`
//   ${tw`absolute top-0 h-full flex items-end items-center z-20`}
//   button {
//     ${tw`bg-white rounded-full p-2 text-secondary-500 hover:text-primary-500 focus:outline-none transition duration-300 transform hover:scale-125 transform -translate-y-2/3 md:translate-y-0 opacity-50`}
//     svg {
//       ${tw`w-8`}
//     }
//   }
// `

// const NextArrow = ({ currentSlide, slideCount, ...props }) => (
//   <SliderControlButtonContainer tw='right-0'>
//     <button {...props}>
//       <ArrowRightIcon />
//     </button>
//   </SliderControlButtonContainer>
// )
// const PreviousArrow = ({ currentSlide, slideCount, ...props }) => (
//   <SliderControlButtonContainer tw='left-0'>
//     <button {...props}>
//       <ArrowLeftIcon />
//     </button>
//   </SliderControlButtonContainer>
// )

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute w-32 top-0 left-0 -z-10 text-primary-500 opacity-25 transform -translate-x-full`
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`absolute w-32 bottom-0 right-0 -z-10 text-pink-500 opacity-15 transform translate-x-2/3 translate-y-8`
const DecoratorBlob3 = tw(
  SvgDecoratorBlob2
)`absolute w-32 top-0 right-0 -z-10 text-pink-500 opacity-15 transform translate-x-2/3 translate-y-8`

const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

export default () => {
  /*
   * You can modify the testimonials shown by modifying the array below
   * You can add or remove objects from the array as you need.
   */
  const images = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12
  ]

  const testimonials = images.map(imageSrc => ({ imageSrc }))

  const Subheading = tw(SubheadingBase)`text-center md:text-left`

  const SlideBG = styled.div`
    ${tw`relative min-h-64 sm:min-h-80 md:min-h-96 lg:min-h-500 rounded`}
    &::before {
      content: '';
      ${tw`absolute inset-0`}
      background-image: url(${props => props.bg});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      opacity: 0.8;
      filter: blur(4px);
    }
  `

  const SlideIMG = styled.div`
    ${tw`relative min-h-64 sm:min-h-80 md:min-h-96 lg:min-h-500 rounded`}
    background-image: url(${props => props.bg});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  `

  const CustomDot = styled.div`
    ${tw`bg-gray-300 rounded-full w-2 h-2 sm:w-4 sm:h-4 mb-2`}
    li.slick-active & {
      background-image: url(${logo});
      background-size: cover;
      background-position: center;
    }
  `

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    customPaging: i => <CustomDot />,
    appendDots: dots => <ul>{dots}</ul>
  }

  return (
    <Container>
      <Content>
        <Subheading>Our Services include</Subheading>

        <Description>
          <span tw='text-primary-500'>○</span> General building service <br />
          <span tw='text-primary-500'>○</span> Refurbishment service <br />
          <span tw='text-primary-500'>○</span> Extension <br />
          <span tw='text-primary-500'>○</span> Bathrooms <br />
          <span tw='text-primary-500'>○</span> Kitchens Electrical <br />
          <span tw='text-primary-500'>○</span> Plumbing & Heating <br />
          <span tw='text-primary-500'>○ </span> Design and consultation service
        </Description>

        <SectionHeading>
          Some of <span tw='text-primary-500'>our latest </span>projects
        </SectionHeading>
        <TestimonialSliderContainer>
          <TestimonialSlider {...settings}>
            {testimonials.map((testimonial, index) => (
              <SlideBG key={index} bg={testimonial.imageSrc}>
                <SlideIMG bg={testimonial.imageSrc} />
              </SlideBG>
            ))}
          </TestimonialSlider>
        </TestimonialSliderContainer>
      </Content>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
      <DecoratorBlob3 />
    </Container>
  )
}
