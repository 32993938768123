// FILE: StyledHeader.js
import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

import Header, {
  NavLink,
  NavLinks,
  LogoLink,
  NavToggle,
  DesktopNavLinks
} from './light.js'

const HeaderContainer = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`

export const navLinks = [
  <NavLinks key={1}>
    <NavLink href='/'>Home</NavLink>
    <NavLink href='about'>About</NavLink>
    <NavLink href='contact'>Contact</NavLink>
    <NavLink href='gallery'>Services & Gallery</NavLink>
  </NavLinks>
]

const CustomHeader = () => {
  return <HeaderContainer links={navLinks} />
}

export default CustomHeader
