import React from 'react'
import AnimationRevealPage from 'helpers/AnimationRevealPage.js'
import { css } from 'styled-components/macro' //eslint-disable-line
import Header from 'components/headers/light.js'
import Footer from 'components/footers/SimpleFiveColumn.js'

import { navLinks } from 'components/headers/CustomHeader'
import Testimonial from 'components/testimonials/TwoColumnWithImage.js'

export default () => {
  return (
    <AnimationRevealPage>
      <Header links={navLinks} />
      <Testimonial />
      <Footer />
    </AnimationRevealPage>
  )
}
